import React from 'react';
import { useNavigate } from 'react-router-dom';
import myLogo from '../images/logofull.png';
//import './HomePage.css'; // Importa el CSS específico de este componente

const HomePage = () => {
  const navigate = useNavigate();
  document.title = 'STIPA - Sistema de Turnos para retiro útiles escolares';

  const goToLogin = () => {
    navigate('/login');
  };

  const goToRegister = () => {
    navigate('/register');
  };

  return (
    <div className="homepage">
      <img src={myLogo} alt="STIPA" className="homepage-imagen-ajustada" />
      <h1>STIPA - Sistema de Turnos para retirar útiles escolares - STIPA</h1>
      <p>
        Bienvenido, si necesitas ayuda contáctate al mail utiles(arroba)stipa.org.ar o llámanos a
        los nros: 4981-5015 / 4776 / 2457
      </p>
      <button onClick={goToLogin} className="homepage-boton-login">
        Entrar al Sistema
      </button>
      <button onClick={goToRegister} className="homepage-boton-registrar">
        ¿Sos nuevo? Regístrate acá
      </button>
    </div>
  );
};

export default HomePage;
